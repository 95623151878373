<template>
  <div class="checkboxes-input">
    <div class="form-check" v-for="option in options" :key="option.value">
      <input type="radio" class="form-check-input" :id="`${option.value}-option`" :value="option.value" v-model="selection">
      <label class="form-check-label" :for="`${option.value}-option`">{{ option.label }}</label>
    </div>
  </div>
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ],
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      selection: null
    }
  },
  created () {
    this.selection = this.value ? this.value : null
  },
  watch: {
    selection (val) {
      this.$emit('value-change', val)
    }
  }
}
</script>
